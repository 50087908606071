import { useCallback, useMemo } from 'react';
import { useLazyQuery } from 'react-apollo';
import type { ApolloError } from 'apollo-client';

import { traverse } from '@atlaskit/adf-utils/traverse';
import type { ADFEntity } from '@atlaskit/adf-utils/types';

import { useBooleanFeatureFlag } from '@confluence/session-data';
import {
	ContentUnifiedQuery,
	type ContentUnifiedQueryType,
	type ContentUnifiedQueryVariablesType,
	type ContentUnifiedQueryContentNodeType,
} from '@confluence/content-unified-query';
import { useSpaceKey } from '@confluence/space-utils';
import { isUnauthorizedError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';

const safeParseADF = (rawADF: string): ADFEntity => {
	try {
		return JSON.parse(rawADF);
	} catch {
		return { type: 'doc' };
	}
};

const mentionedAccountLocalIdMapping: Record<string, string[]> = {};

const parseMentionedAccounts = (pageContentADF: string): Record<string, string> => {
	const parsedADF = safeParseADF(pageContentADF);
	const mentionedAccounts: Record<string, string> = {};

	traverse(parsedADF, {
		mention: (node) => {
			const { id, localId, text = '' } = node.attrs ?? {};
			if (id) {
				mentionedAccounts[id] = text.replace(/^@/, '');
			}
			if (
				localId &&
				(!mentionedAccountLocalIdMapping[id] ||
					!mentionedAccountLocalIdMapping[id].includes(localId))
			) {
				const currentLocalIds = mentionedAccountLocalIdMapping[id] || [];
				// opting not to use spread/concat to prevent recreating array with unknown length
				currentLocalIds.push(localId);
				mentionedAccountLocalIdMapping[id] = currentLocalIds;
			}
		},
	});

	return mentionedAccounts;
};

type UsePageMentionedAccountIdsResult = {
	isLoading: boolean;
	mentionedAccountIds: string[] | null;
	mentionedAccountNames: Record<string, string> | null;
	mentionedAccountLocalIdMapping: Record<string, string[]>;
	loadMentionedAccountIds: () => void;
	lastModifiedDate?: string;
	error: ApolloError | undefined;
};

export const usePageMentionedAccountIdsV2 = (
	contentId: string,
): UsePageMentionedAccountIdsResult => {
	const spaceKey = useSpaceKey();
	const isSpaceAliasFFEnabled = useBooleanFeatureFlag('confluence.frontend.space.alias');
	const isNewContentTopperFFEnabled = useBooleanFeatureFlag(
		'confluence.frontend.custom-sites.page-header-and-title',
	);

	const [
		fetchContentUnifiedADF,
		{ data: contentADFData, loading: contentADFLoading, error: contentADFError },
	] = useLazyQuery<ContentUnifiedQueryType, ContentUnifiedQueryVariablesType>(ContentUnifiedQuery);

	const loadMentionedAccountIds = useCallback(() => {
		fetchContentUnifiedADF({
			variables: {
				contentId,
				spaceKey,
				includeAlias: isSpaceAliasFFEnabled,
				useNewContentTopper: isNewContentTopperFFEnabled,
				versionOverride: null,
			},
		});
	}, [
		isSpaceAliasFFEnabled,
		isNewContentTopperFFEnabled,
		fetchContentUnifiedADF,
		contentId,
		spaceKey,
	]);

	let adf;
	let lastModifiedDate;

	if (contentADFData) {
		const normalizedResult = contentADFData;
		const contentNode = normalizedResult?.content?.nodes?.[0] as ContentUnifiedQueryContentNodeType;

		adf = contentNode?.body?.dynamic?.value;
		lastModifiedDate = contentNode?.metadata?.lastModifiedDate;
	}

	const mentionedAccountNames = useMemo<Record<string, string> | null>(() => {
		if (!contentADFData) {
			return null;
		}
		if (!adf) {
			return {};
		}
		return parseMentionedAccounts(adf);
	}, [contentADFData, adf]);

	const mentionedAccountIds = useMemo<string[] | null>(() => {
		if (!mentionedAccountNames) {
			return null;
		}
		return Object.keys(mentionedAccountNames);
	}, [mentionedAccountNames]);

	const error = contentADFError;
	if (error) {
		if (!isUnauthorizedError(error)) {
			markErrorAsHandled(contentADFError);
		}
	}

	return {
		isLoading: contentADFLoading,
		mentionedAccountIds,
		mentionedAccountNames,
		mentionedAccountLocalIdMapping,
		loadMentionedAccountIds,
		lastModifiedDate,
		error: contentADFError,
	};
};
