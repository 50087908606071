/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum SitePermissionType {
  ANONYMOUS = "ANONYMOUS",
  APP = "APP",
  EXTERNAL = "EXTERNAL",
  INTERNAL = "INTERNAL",
  JSD = "JSD",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AnalyticsViewersQuery
// ====================================================

export interface AnalyticsViewersQuery_singleContent_contentAnalyticsViewsByUser_pageViews_userProfile_photos {
  value: string | null;
  isPrimary: boolean | null;
}

export interface AnalyticsViewersQuery_singleContent_contentAnalyticsViewsByUser_pageViews_userProfile_confluence {
  permissionType: SitePermissionType | null;
}

export interface AnalyticsViewersQuery_singleContent_contentAnalyticsViewsByUser_pageViews_userProfile {
  id: string | null;
  displayName: string | null;
  photos: (AnalyticsViewersQuery_singleContent_contentAnalyticsViewsByUser_pageViews_userProfile_photos | null)[] | null;
  isActive: boolean | null;
  confluence: AnalyticsViewersQuery_singleContent_contentAnalyticsViewsByUser_pageViews_userProfile_confluence | null;
}

export interface AnalyticsViewersQuery_singleContent_contentAnalyticsViewsByUser_pageViews {
  userProfile: AnalyticsViewersQuery_singleContent_contentAnalyticsViewsByUser_pageViews_userProfile | null;
  views: number;
  lastViewedAt: string;
  lastVersionViewed: number;
  lastVersionViewedUrl: string | null;
}

export interface AnalyticsViewersQuery_singleContent_contentAnalyticsViewsByUser {
  pageViews: AnalyticsViewersQuery_singleContent_contentAnalyticsViewsByUser_pageViews[];
}

export interface AnalyticsViewersQuery_singleContent {
  id: string | null;
  contentAnalyticsViewsByUser: AnalyticsViewersQuery_singleContent_contentAnalyticsViewsByUser | null;
}

export interface AnalyticsViewersQuery {
  singleContent: AnalyticsViewersQuery_singleContent | null;
}

export interface AnalyticsViewersQueryVariables {
  contentId: string;
  limit?: number | null;
  accountIds?: (string | null)[] | null;
}
